import React, { Component } from 'react'

import BookingPhotoTest from './BookingPhotoTest'


export default class BookingPhotoView extends Component {
  render() {
    return (
      <>
     {/* <Header {...this.props}></Header> */}
     <BookingPhotoTest data={BookingPhotoTest.hero}></BookingPhotoTest>
      </>
    )
  }
}
