import React from "react";
import imageHero from "../../assets/images/newpoo/test3.png";
import startbutton from "../../assets/images/newpoo/snakebutton.png";

import { useHistory } from "react-router-dom";

import "../../assets/fonts/MatSaleh.otf";

export default function ClickPage(props) {
  const history = useHistory();

  function openDSLR() {
    window
      .open(
        "http://localhost:1500/api/start?mode=print&password=RnoTi8lGHgEE9VCM",
        "_blank"
      )
      .focus();
    // window
    //   .open(
    //     "http://localhost:1500/api/start?mode=print&password=bP_A6u6sKblbviUR",
    //     // "https://localhost:1500/api/start?mode=print&password=2dqE4rN4nIZ3yR-y",
    //     "_blank"
    //   )
    //   .focus();
  }

  return (
    <div
    style={{
      backgroundImage: `url(${imageHero})`, // Set tatacara image as background
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "1000px", // Set the height of the background div
      textAlign: "center", // Center content horizontally
      display: "flex", // Center content vertically
      justifyContent: "center", // Center content vertically
      alignItems: "center", // Center content vertically
      position: "relative", // Add position relative for button positioning
      flexDirection: "column", // Arrange items in a column
    }}
  >
    <img
      src={startbutton}
      style={{
        cursor: "pointer",
        animation: "zoomInOut 3s infinite alternate",
        width:500,
        height:300
      }}
      onClick={() => openDSLR()}
    />
           <style>
    {`
    @keyframes zoomInOut {
      0% {
        transform: scale(1); // Initial scale
      }
      50% {
        transform: scale(1.1); // Zoom in
      }
      100% {
        transform: scale(1); // Zoom out
      }
    }
    `}
  </style>
      </div>

  
  );
}
