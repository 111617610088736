import React, { Component } from 'react'

import AfterBooking from './AfterBooking'


export default class AfterBookingView extends Component {
  render() {
    return (
      <>
     {/* <Header {...this.props}></Header> */}
     <AfterBooking data={AfterBooking.hero}></AfterBooking>
      </>
    )
  }
}
