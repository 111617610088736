import React, { Component } from 'react'

import RecordTicket from './Record_Ticket'


export default class DashboardTicketView extends Component {
  render() {
    return (
      <>
     {/* <Header {...this.props}></Header> */}
     <RecordTicket data={RecordTicket.hero}></RecordTicket>
      </>
    )
  }
}
