import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';

export default function RecordRating(props) {
  const [listRating, setListRating] = useState([]);
  const [countRating, setCountRating] = useState({
    star5: 0,
    star4: 0,
    star3: 0,
    star2: 0,
    star1: 0
  });

  const history = useHistory();

  function formatDateOrder(date) {
    var newdate = new Date(date);
    var getdate = newdate.getDate();
    var getmonth = newdate.getMonth();
    var getyear = newdate.getFullYear();
    var tgl = getdate < 10 ? '0' + getdate : getdate;
    var bulan = getmonth + 1 < 10 ? '0' + (getmonth + 1) : getmonth + 1;
    return `${tgl}/${bulan}/${getyear}`;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      LoadData();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    LoadData();
  }, []);

  const LoadData = () => {
    Axios.get('https://be.studiopoonya.net/rating')
      .then((res) => {
        const sortedData = res.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setListRating(sortedData);

        // Menghitung jumlah rating bintang 5, 4, 3, 2, 1
        const count = { star5: 0, star4: 0, star3: 0, star2: 0, star1: 0 };
        sortedData.forEach(item => {
          if (item.rating === 5) count.star5++;
          else if (item.rating === 4) count.star4++;
          else if (item.rating === 3) count.star3++;
          else if (item.rating === 2) count.star2++;
          else if (item.rating === 1) count.star1++;
        });
        setCountRating(count);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  return (
    <div>
      <section id="sidebar">
        <a href="#" className="brand">
          <i className="bx bxs-smile"></i>
          <span className="text">Poonya Admin</span>
        </a>
        <ul className="side-menu top">
          <li>
            <a href="/Dashboard">
              <i className="bx bxs-dashboard"></i>
              <span className="text">Dashboard</span>
            </a>
          </li>
          <li>
            <a href="/Dashboard/Generate">
              <i className="bx bxs-doughnut-chart"></i>
              <span className="text">Generate Code</span>
            </a>
          </li>
          <li>
            <a href="/Referral-Code">
              <i className="bx bxs-doughnut-chart"></i>
              <span className="text">Referral Code</span>
            </a>
          </li>
          <li className="active">
            <a href="/ReferralTransaction">
              <i className="bx bxs-doughnut-chart"></i>
              <span className="text">Referral Transaction</span>
            </a>
          </li>
          <li>
            <a href="/EditPrice">
              <i className="bx bxs-doughnut-chart"></i>
              <span className="text">Edit Price</span>
            </a>
          </li>
          <li>
            <a href="/EditVoucher">
              <i className="bx bxs-doughnut-chart"></i>
              <span className="text">Custom Voucher</span>
            </a>
          </li>
          <li>
            <a href="/Dashboard-Rating">
              <i className="bx bxs-doughnut-chart"></i>
              <span className="text">Rating Record</span>
            </a>
          </li>
        </ul>
        <ul className="side-menu">
          <li>
            <a href="/LoginPage" className="logout">
              <i className="bx bxs-log-out-circle"></i>
              <span className="text">Logout</span>
            </a>
          </li>
        </ul>
      </section>

      <section id="content">
        <main>
          <div className="head-title">
            <div className="left">
              <h1>Referral Transactions</h1>
              <ul className="breadcrumb">
                <li>
                  <a href="#">Referral Transactions</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>
                </li>
              </ul>
            </div>
          </div>
          <ul className="box-info">
            <a href="#">
              <li>
                <i className="bx bxs-star"></i>
                <span className="text">
                  <h3>{countRating.star5}</h3>
                  <p>Total Rating 5</p>
                </span>
              </li>
            </a>
            <a href="#">
              <li>
                <i className="bx bxs-star"></i>
                <span className="text">
                  <h3>{countRating.star4}</h3>
                  <p>Total Rating 4</p>
                </span>
              </li>
            </a>
            <a href="#">
              <li>
                <i className="bx bxs-star"></i>
                <span className="text">
                  <h3>{countRating.star3}</h3>
                  <p>Total Rating 3</p>
                </span>
              </li>
            </a>
            <a href="#">
              <li>
                <i className="bx bxs-star"></i>
                <span className="text">
                  <h3>{countRating.star2}</h3>
                  <p>Total Rating 2</p>
                </span>
              </li>
            </a>
            <a href="#">
              <li>
                <i className="bx bxs-star"></i>
                <span className="text">
                  <h3>{countRating.star1}</h3>
                  <p>Total Rating 1</p>
                </span>
              </li>
            </a>
          </ul>

          <div className="table-data">
            <div className="order">
              <div className="head">
                <h3>Recent Orders</h3>
                <i className="bx bx-search"></i>
                <i className="bx bx-filter"></i>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Rating</th>
                    <th>Email</th>
                    <th>Date Order</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    listRating.map((item, index) => (
                      <tr key={item.id}>
                        <td><p>{index + 1}</p></td>
                        <td><p>{item.rating}</p></td>
                        <td><p>{item.email}</p></td>
                        <td>{formatDateOrder(item.createdAt)}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </main>
      </section>

      <style></style>

      <script src="assets/js/dashboard.js"></script>
    </div>
  );
}