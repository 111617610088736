import ReferralTransaction from './ReferralTransaction'

import React from 'react'

const ReferralTransactionView = () => {
  return (
    <ReferralTransaction data={ReferralTransaction.hero}/>
  )
}

export default ReferralTransactionView