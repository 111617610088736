import React from 'react'

import './AfterBooking.css'
import {ReactComponent as YourSvg } from "../../assets/images/book1.svg";



export default function AfterBooking(props){
return(
    <div class="logo">
{/* import {ReactComponent as YourSvg } from "../../assets/images/book1.svg"; */}

      <YourSvg  style={{width:300, height:300, marginTop:200, marginLeft:40,}}/>    
    </div>
    )
}




