import React, { useState } from "react";
import { useEffect } from "react";

import ratebg from "../../assets/images/newpoo/ratebg.jpg";
import ratesg from "../../assets/images/newpoo/ratesg.jpg";
import finishicon from "../../assets/images/newpoo/iconfinish.png"
import finishtext from "../../assets/images/newpoo/textfinish.png"


import Axios from "axios";
import { useHistory } from "react-router-dom";
import { FaStar } from 'react-icons/fa'; // Import star icon

import { ReactComponent as YourSvg } from "../../assets/images/logopoonya.svg";

import "../../assets/fonts/MatSaleh.otf";

export default function FinishPage(props) {
  const history = useHistory();
  const [inputRedeem, setInputRedeem] = useState();
  const [modalShow, setModalShow] = React.useState(false);
  const [modalQris, setModalQris] = React.useState(false);
  const [qrCode, setQRCode] = React.useState();
  const [orderId, setOrderId] = React.useState();
  const [price, setPrice] = React.useState();

  const [hoveredStar, setHoveredStar] = useState(0);
  const [email, setEmail] = useState();
  const [selectedStar, setSelectedStar] = useState(0);
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(timer);
          window.location.href = 'https://photo.studiopoonya.net/';
        }
        return prevCountdown - 1;
      });
    }, 1000);

    return () => clearInterval(timer); // Clean up the timer on component unmount
  }, []);

  

  const handleSubmit = () => {
    const body = {
      email: email,
      rating: selectedStar
    }
    Axios.post('https://be.studiopoonya.net/rating', body)
    .then((res) => {
      console.log(res)
    })
    .catch(err => console.log(err)) 
  }

  


  return (
    <div
    style={{
      backgroundImage: `url(${ratebg})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "1000px",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      flexDirection: "column",
    }}
  >
    <div style={{ position: "relative", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <img
        src={finishtext}
        style={{
          borderRadius: 15,
          display: "block",
        }}
        alt="Ratesg"
      />
      <img
        src={finishicon}
        style={{
          borderRadius: 15,
          display: "block",
          marginTop: "10px", // Optional: Adds some space between the text and icon
          width: "700px",
          height: "800px"
        }}
        alt="Ratesg"
      />
    </div>
    <div
      style={{
        position: "absolute",
        top: "20px", // Adjust to move it further down or up from the top
        right: "20px", // Adjust to move it further in or out from the right
        textAlign: "center",
        color: "#000",
        width: "100px", // Size of the circle
        height: "100px", // Size of the circle
        borderRadius: "50%",
        backgroundColor: "#FFF",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "40px",
        fontWeight: "bold",
      }}
    >
      {countdown}
    </div>
  </div>
  
  );
}
