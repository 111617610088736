import React, { useState } from "react";
import { useEffect } from "react";
import imageHero from "../../assets/images/newpoo/bgpoo.jpg";
import startbutton from "../../assets/images/newpoo/startbutton.png";
import tatacara from "../../assets/images/tatacara.jpg";
import lineredem from "../../assets/images/newpoo/redem_line.png";
import redembutton from "../../assets/images/newpoo/redem_button.png";
import qrisicon from "../../assets/images/newpoo/qrisicon.png";
import paid from "../../assets/images/newpoo/paid.png";
import closed from "../../assets/images/newpoo/close.png";

import redemarrow from "../../assets/images/newpoo/redem_arrow.png";
import poonyalogo from "../../assets/images/poonyalogo.png";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import QRCode from "react-qr-code";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as YourSvg } from "../../assets/images/logopoonya.svg";

import "../../assets/fonts/MatSaleh.otf";


export default function Hero(props) {
  const history = useHistory();
  const [inputRedeem, setInputRedeem] = useState();
  const [modalShow, setModalShow] = React.useState(false);
  const [modalQris, setModalQris] = React.useState(false);
  const [qrCode, setQRCode] = React.useState();
  const [orderId, setOrderId] = React.useState();
  const [price, setPrice] = React.useState();
  // const [countdown, setCountdown] = useState(60);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setCountdown((prevCountdown) => {
  //       if (prevCountdown <= 1) {
  //         clearInterval(timer);
  //         window.location.href = 'https://photo.studiopoonya.net/';
  //       }
  //       return prevCountdown - 1;
  //     });
  //   }, 1000);

  //   return () => clearInterval(timer); // Clean up the timer on component unmount
  // }, []);

  function randomString(len, charSet) {
    charSet = charSet || "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    var randomString = "";
    for (var i = 0; i < len; i++) {
      var randomPoz = Math.floor(Math.random() * charSet.length);
      randomString += charSet.substring(randomPoz, randomPoz + 1);
    }
    return randomString;
  }

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Tata Cara Pembayaran
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
            <img src={tatacara} width="400" height="500" />
          </center>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function ModalQrisShow(props) {
    return (
      <Modal
        {...props}
        size="s"
        aria-labelledby="contained-modal-title-vcenter"
        centered
       
      >
        {/* <div style={{ borderRadius: '15px' }}> */}
        <Modal.Header>
          <Modal.Body>
            <center>
              <img src={qrisicon} />
              {/* <YourSvg
              
              style={{ width: "80", height: "80", color: "red" }}
            />{" "} */}
              <div style={{ marginTop: 10 }}></div>
              {/* <h4 className="align-center" style={{ textAlign: "center",  color: "#0950E0",  fontSize: 30,}}>    
              Studio Poonya
            </h4> */}
            </center>
          </Modal.Body>
        </Modal.Header>
        <Modal.Body>
          <h2 className="align-center" style={{ textAlign: "center" }}>
            Scan QR Code
          </h2>
          <center>
            <QRCode
              value={qrCode}
              style={{ width: "250", height: "250", color: "red" }}
            />{" "}
            <div style={{ marginTop: 10 }}></div>
            <h2 className="align-center" style={{ textAlign: "center" }}>
              Rp {parseFloat(price)}
            </h2>
            <div style={{ marginTop: 10 }}></div>
            <h4
              className="align-center"
              id="hiddentext"
              style={{ textAlign: "center", color: "red", display: "none" }}
            >
              Pastikan kembali pembayaran anda
            </h4>
          </center>
        </Modal.Body>
        <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={closed}
              onClick={props.onHide}
              alt="Close Button"
              style={{
                height: "58px",
                width: "200px",
                cursor: "pointer",
                margin: "0 10px",
              }}
            />
            <img
              src={paid}
              onClick={() => checkPaymentQR()}
              alt="Paid Button"
              style={{
                height: "70px",
                width: "200px",
                cursor: "pointer",
                margin: "0 10px",
              }}
            />
          </div>
        </Modal.Footer>
        {/* </div> */}
      </Modal>
    );
  }

  function generateQR() {
    Axios.post("https://be.studiopoonya.net/payment-qr")
      .then((result) => {
        console.log(result);
        setQRCode(result.data.response.qr_string);
        setOrderId(result.data.response.order_id);
        setPrice(result.data.response.gross_amount);
        setModalQris(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function generateQRCustomVoucher(res) {
    console.log(res);
    Axios.post(`https://be.studiopoonya.net/payment-qr/custom-voucher/${res}`)
      .then((result) => {
        console.log("sukses");
        console.log(result);
        setQRCode(result.data.response.qr_string);
        setOrderId(result.data.response.order_id);
        setPrice(result.data.response.gross_amount);
        setModalQris(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function checkPaymentQR() {
    Axios.get(`https://be.studiopoonya.net/payment-qr/status/${orderId}`)
      .then((res) => {
        console.log(res);
        if (res.data.response.transaction_status == "settlement") {
          console.log("update status");
          Axios.put(
            `https://be.studiopoonya.net/payment/update/status/${orderId}`
          );
          history.push("ClickPage");
        } else {
          var x = document.getElementById("hiddentext");
          if (x.style.display === "none") {
            x.style.display = "block";
          } else {
            x.style.display = "none";
          }
        }
      })
      .catch((error) => {
        console.log("error");
      });
  }

  useEffect(() => {
    // Sandbox
    // const snapSrcUrl = 'https://app.sandbox.midtrans.com/snap/snap.js';
    // const myMidtransClientKey = 'SB-Mid-client-LuRh4t6hempU6vad'; //change this according to your client-key

    //Production
    const snapSrcUrl = "https://app.midtrans.com/snap/snap.js";
    const myMidtransClientKey = "Mid-client-XZCR9t5ePdhcGaNC"; //change this according to your client-key

    const script = document.createElement("script");
    script.src = snapSrcUrl;
    script.setAttribute("data-client-key", myMidtransClientKey);
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const RedeemCode = () => {
    Axios.get(
      `https://be.studiopoonya.net/payment/number/${inputRedeem}`
      // `http://localhost:5000/payment/number/${inputRedeem}`
    )
      .then((res) => {
        console.log("135", res.data);

        //Redeem Voucher Code
        if (
          res.data.transaction_number == inputRedeem &&
          res.data.payment_method == "adminredeem" &&
          res.data.pay_status == "Completed"
        ) {
          if (res.data.ticket_used == false) {
            console.log(res.data.transaction_number);
            return Axios.put(
              `https://be.studiopoonya.net/payment/update/status/${res.data.transaction_number}`
            )
              .then((result) => {
                Axios.put(
                  `https://be.studiopoonya.net/payment/update/ticketused/${res.data.transaction_number}`
                )
                  .then((result) => {
                    history.push("ClickPage");
                    history.go(0);
                  })
                  .catch((error) => {
                    console.log("error");
                  });
              })
              .catch((error) => {
                console.log("error");
              });
          } else {
            alert("The code has been used before");
          }
        }

        //Redeem Custom Voucher Code
        if (
          res.data.transaction_number == inputRedeem &&
          res.data.payment_method == "adminredeem" &&
          res.data.pay_status == "Pending"
        ) {
          if (res.data.ticket_used == false) {
            console.log(res.data.transaction_number);
            generateQRCustomVoucher(res.data.transaction_number);
            // return Axios.put (
            //   `https://be.studiopoonya.net/payment/update/status/${res.data.transaction_number}`
            // )
            //   .then((result) => {
            //     Axios.put(`https://be.studiopoonya.net/payment/update/ticketused/${res.data.transaction_number}`)
            //     .then((result) => {
            //       history.push('ClickPage')
            //       history.go(0)
            //     })
            //     .catch((error) => {
            //       console.log("error");
            //     });

            //   })
            //   .catch((error) => {
            //     console.log("error");
            //   });
          } else {
            alert("The code has been used before");
          }
        }

        //Redeem Referral Code (Payment Completed)
        else if (
          res.data.payment_method == "referralcode" &&
          res.data.pay_status == "Completed" &&
          res.data.ticket_used == false
        ) {
          Axios.put(
            `https://be.studiopoonya.net/payment/update/ticketused/${res.data.transaction_number}`
          )
            .then((result) => {
              history.push("ClickPage");
              history.go(0);
            })
            .catch((error) => {
              console.log("error");
            });
        } else if (
          res.data.payment_method == "referralcode" &&
          res.data.pay_status == "Pending" &&
          res.data.ticket_used == false
        ) {
          Axios.get(
            `https://be.studiopoonya.net/payment-qr/status/${res.data.transaction_number}`
          ).then((temp) => {
            console.log(temp);
            if (temp.data.response.transaction_status == "settlement") {
              console.log(res.data.transaction_number);
              return Axios.put(
                `https://be.studiopoonya.net/payment/update/ticketused/${res.data.transaction_number}`
              )
                .then((result) => {
                  console.log("berhasil");
                  history.push("ClickPage");
                  history.go(0);
                })
                .catch((error) => {
                  console.log("error");
                });
            } else {
              alert("Make sure your payment is successfull");
            }
          });
        } else if (
          res.data.payment_method == "adminreferral" &&
          res.data.pay_status == "Pending" &&
          res.data.active == true
        ) {
          Axios.post(
            `https://be.studiopoonya.net/payment-qr/referral/${res.data.transaction_number}`
            // `http://localhost:5000/payment-qr/referral/${res.data.transaction_number}`
          )
            .then((result) => {
              console.log(result);
              setQRCode(result.data.response.qr_string);
              setOrderId(result.data.response.order_id);
              setPrice(result.data.response.gross_amount);
              setModalQris(true);
            })
            .catch((error) => {
              console.log("error", error);
            });
        } else if (
          res.data.transaction_number == inputRedeem &&
          res.data.payment_method == "Booking Photo" &&
          res.data.pay_status == "Completed" &&
          res.data.ticket_used == false
        ) {
          Axios.put(
            `https://be.studiopoonya.net/payment/update/ticketused/${res.data.transaction_number}`
          )
            .then((result) => {
              console.log("suksess");
              // console.log(result);
              history.push("ClickPage");
              history.go(0);
            })
            .catch((error) => {
              console.log("error");
            });
        } else {
          alert(
            "Please make sure the payment is completed and the code is correct"
          );
        }
      })
      .catch((error) => {
        console.log("error");
        alert("The code is wrong");
      });
  };

  return (
<div
  style={{
    backgroundImage: `url(${imageHero})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "1000px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    flexDirection: "column",
  }}
>
  <img
    src={startbutton}
    style={{
      cursor: "pointer",
      animation: "zoomInOut 3s infinite alternate",
      zIndex: 1,
    }}
    onClick={() => generateQR()}
  />
  <div
    style={{
      marginTop: "25px",
      position: "relative",
      width: "600px",
      height: "100px",
      display: "flex",
      alignItems: "center",
      zIndex: 2,
    }}
  >
    <div
      style={{
        flex: "1",
        height: "100%",
        backgroundImage: `url(${lineredem})`,
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        position: "relative",
        zIndex: 3,
      }}
    >
      <input
        type="text"
        onChange={(e) => setInputRedeem(e.target.value)}
        className="codeInput"
        placeholder="Input Your Code Here"
        style={{
          position: "absolute",
          top: "45%",
          left: "35%",
          transform: "translate(-50%, -50%)",
          padding: "3px",
          fontSize: "20px",
          borderBottom: "2px solid black",
          textAlign: "center",
          color: "black",
        }}
      />
      <img
        src={redembutton}
        onClick={() => RedeemCode()}
        style={{
          position: "absolute",
          top: "50%",
          left: "80%",
          transform: "translate(-50%, -50%)",
          padding: "10px 20px",
          fontSize: "16px",
          cursor: "pointer",
        }}
      />
    </div>
  </div>
  <div
    style={{
      position: "absolute",
      top: "10px",
      right: "10px",
      backgroundColor: "white",
      borderRadius: "50%",
      width: "60px",
      height: "60px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "25px",
      fontWeight: "bold",
      color: "black",
      zIndex: 4,
    }}
  >
    {/* {countdown} */}
  </div>
  <>
    <MyVerticallyCenteredModal
      show={modalShow}
      onHide={() => setModalShow(false)}
    />
    <ModalQrisShow
      show={modalQris}
      onHide={() => setModalQris(false)}
    />
  </>
  <style>
    {`
      @keyframes zoomInOut {
        0% {
          transform: scale(1); // Initial scale
        }
        50% {
          transform: scale(1.1); // Zoom in
        }
        100% {
          transform: scale(1); // Zoom out
        }
      }
    `}
  </style>
</div>
  

    
  );
}
