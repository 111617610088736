import React, { useState } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import "assets/scss/Records.scss";


import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";


export default function ReferralCode(props) {
  const [totalamount, setTotalAmount] = useState();
  const [listTransaction, setListTransaction] = useState([]);
  const [totalTransaction, setTotalTransaction] = useState();
  const [totalTicketUsed, setTotalTicketUsed] = useState();
  const [inputPrice, setInputPrice] = useState();
  const history = useHistory()
  const [inputReferral, setInputReferral] = useState();
  const [currentPrice, setCurrentPrice] = useState([]);

  const [startDate, setStartDate] = useState();
  const [finishDate, setFinishDate] = useState();


  
  function convertToRupiah(angka) {
    var rupiah = "";
    var angkarev = angka.toString().split("").reverse().join("");
    for (var i = 0; i < angkarev.length; i++)
      if (i % 3 == 0) rupiah += angkarev.substr(i, 3) + ".";
    return (
      "Rp. " +
      rupiah
        .split("", rupiah.length - 1)
        .reverse()
        .join("")
    );
  }

  function formatDateOrder(date) {
    var newdate = new Date(date);
    var getdate = newdate.getDate();
    var getmonth = newdate.getMonth();
    var bulan;
    var tgl;
    if (getdate < 10) {
      tgl = "0" + getdate;
    } else {
      tgl = getdate;
    }
    if (getmonth + 1 < 10) {
      bulan = "0" + (getmonth + 1);
    } else {
      bulan = getmonth + 1;
    }
    var getyear = newdate.getFullYear();
    return `${getdate}/${bulan}/${getyear}`;
    // return 'Rp. ' + rupiah.split('', rupiah.length - 1).reverse().join('');
  }

  Axios.get("https://be.studiopoonya.net/payment")
    .then((res) => {
      // console.log(res);
      setListTransaction(res.data);
      // console.log(res.data)
      setTotalTransaction(res.data.length);
    })
    .catch((error) => {
      console.log("error");
    });


  const Referral = () => {
    var body = {
      transaction_number: inputReferral,
      price: inputPrice,
    };

    Axios.post(`https://be.studiopoonya.net/admin/voucher/referral`, body)
      .then((res) => {
        console.log(res);
        alert("sukses");
      })
      .catch((error) => {
        console.log("error");
        alert("gagal");
      });
  };

  const Delete_Referral = (code) => {
    var username = code
   
    Axios.delete(`https://be.studiopoonya.net/payment/delete/referral/${code}`)
      .then((res) => {
        console.log(res);
        alert("delete sukses");
        // history.go(0)
      })
      .catch((error) => {
        console.log("error");
        alert("delete gagal");
      });
  };

  return (
    <div>
      <section id="sidebar">
        <a href="#" className="brand">
          <i className="bx bxs-smile"></i>
          <span className="text">Poonya Admin</span>
        </a>
        <ul className="side-menu top">
          <li>
            <a href="/Dashboard">
              <i className="bx bxs-dashboard"></i>
              <span className="text">Dashboard</span>
            </a>
          </li>
          <li>
            <a href="/Dashboard/Generate">
              <i className="bx bxs-doughnut-chart"></i>
              <span className="text">Generate Code</span>
            </a>
          </li>
          <li className="active">
            <a href="./Referral-Code">
              <i className="bx bxs-doughnut-chart"></i>
              <span className="text">Referral Code</span>
            </a>
          </li>
          <li>
            <a href="/ReferralTransaction">
              <i className="bx bxs-doughnut-chart"></i>
              <span className="text">Referral Transaction</span>
            </a>
          </li>
          <li>
            <a href="./EditPrice">
              <i className="bx bxs-doughnut-chart"></i>
              <span className="text">Edit Price</span>
            </a>
          </li>
          <li>
            <a href="./EditVoucher">
              <i className="bx bxs-doughnut-chart"></i>
              <span className="text">Custom Voucher</span>
            </a>
          </li>
        </ul>
        <ul className="side-menu">
          <li>
            <a href="./LoginPage" className="logout">
              <i className="bx bxs-log-out-circle"></i>
              <span className="text">Logout</span>
            </a>
          </li>
        </ul>
      </section>

      <section id="content">
        <nav>
          {/* <i className="bx bx-menu"></i>
          <a href="#" className="nav-link">
            Categories
          </a> */}
          {/* <form action="#">
            <div className="form-input">
              <input type="search" placeholder="Search..." />
              <button type="submit" className="search-btn">
                <i className="bx bx-search"></i>
              </button>
            </div>
          </form> */}
          {/* <input type="checkbox" id="switch-mode" hidden />
          <label for="switch-mode" className="switch-mode"></label>
          <a href="#" className="notification">
            <i className="bx bxs-bell"></i>
            <span className="num">8</span>
          </a>
          <a href="#" className="profile">
            <img src="img/people.png" />
          </a> */}
        </nav>

        <main>
          <div className="head-title">
            <div className="left">
              <h1>Referral Code</h1>
              <ul className="breadcrumb">
                <li>
                  <a href="#">Referral Code </a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>
                </li>
                {/* <li>
                  <a className="active" href="#">
                    Home
                  </a>
                </li> */}
              </ul>
            </div>
            {/* <a href="#" className="btn-download">
              <i className="bx bxs-cloud-download"></i>
              <span className="text">Download PDF</span>
            </a> */}
          </div>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Input Code"
              id="input_code"
              onChange={(e) => setInputReferral(e.target.value)}
            />
          </div>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Input Pricing"
              id="input_price"
              onChange={(e) => setInputPrice(e.target.value)}
            />
          </div>

          <h6>Start Time</h6>
            <DatePicker
              wrapperClassName="datePicker" 
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              showTimeSelect
              dateFormat="dd/MM/yyyy; hh:mm"
              timeIntervals={60}
            />
            <div style={{ height: 10 }}></div>
            <h6>Finish Time</h6>
            <DatePicker
              wrapperClassName="datePicker"
              selected={finishDate}
              onChange={(date) => setFinishDate(date)}
              showTimeSelect
              dateFormat="dd/MM/yyyy; hh:mm"
              timeIntervals={60}
            />
          <form>
            <button
              onClick={() => Referral()}
              type="button"
              className="btn btn-primary"
            >
              Submit
            </button>
          </form>
         

          <div className="table-data">
            <div className="order">
              <div className="head">
                <h3>Recent Pricing</h3>
                <i className="bx bx-search"></i>
                <i className="bx bx-filter"></i>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Referral Code</th>
                    <th>Price</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Delete Referral</th>
                    
                  </tr>
                </thead>
                <tbody>
                  {listTransaction.map((item, index) => {
                    if (item.payment_method == "adminreferral") {
                      return (
                        <tr key={item.transaction_number}>
                          <td>
                            <p>{index}</p>
                          </td>
                          <td>
                            <p>{item.transaction_number}</p>
                          </td>
                          <td>
                            <p>{item.totalprice}</p>
                          </td>
                          <td>{formatDateOrder(item.createdAt)}</td>
                         
                          <td>
                            <span className="status pending">Ongoing</span>
                          </td>
                          <td>
                            <button onClick={() => Delete_Referral(item.transaction_number)} type="button" className="btn btn-danger">Delete</button>                          
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </main>
      </section>

      <style></style>

      <script src="assets/js/dashboard.js"></script>
    </div>
  );
}
