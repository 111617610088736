import React, { Component } from 'react'

// import Voucher from 'parts/Voucher'
import ReferralCode from './ReferralCode'


export default class ReferralCodeView extends Component {
  render() {
    return (
      <>
     {/* <Header {...this.props}></Header> */}
     <ReferralCode data={ReferralCode.hero}></ReferralCode>
      </>
    )
  }
}
