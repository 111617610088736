import React, { Component } from 'react'

// import Voucher from 'parts/Voucher'
import GenerateCode from './GenerateCode'


export default class GenerateCodeView extends Component {
  render() {
    return (
      <>
     {/* <Header {...this.props}></Header> */}
     <GenerateCode data={GenerateCode.hero}></GenerateCode>
      </>
    )
  }
}
