import React, { Component } from 'react'

import RecordReferral from './Record_Referral'


export default class DashboardReferralView extends Component {
  render() {
    return (
      <>
     {/* <Header {...this.props}></Header> */}
     <RecordReferral data={RecordReferral.hero}></RecordReferral>
      </>
    )
  }
}
