import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import "./login.scss";

const initState = {
  username: "",
  password: "",
};

export default function FormLogin(props) {
  const history = useHistory();
  const [form, setForm] = useState(initState);
  const [error, setError] = useState("");

  const handleLogin = async () => {
    try {
      const response = await Axios.post("https://be.studiopoonya.net/login", form);
      const { token } = response.data;

      // Simpan token ke localStorage
      localStorage.setItem("access_token", token);
      history.push("/Dashboard");
      history.go(0)
    } catch (error) {
      setError("Login failed. Please check your credentials.");
    }
  };

  return (
    <div className="wrapper">
    <form className="form-signin">
      <img src="/path/to/logo.png" alt="Logo" style={{ width: "100px", marginBottom: "1rem" }} />
      <h2 className="form-signin-heading">Admin Poonya</h2>
      <input
        onChange={(e) => setForm({ ...form, username: e.target.value })}
        type="text"
        className="form-control"
        name="username"
        placeholder="Email Address"
        required=""
      />
      <br />
      <input
        onChange={(e) => setForm({ ...form, password: e.target.value })}
        type="password"
        className="form-control"
        name="password"
        placeholder="Password"
        required=""
      />
      <button
        onClick={handleLogin}
        className="btn btn-lg btn-primary btn-block"
        type="button"
      >
        Login
      </button>
      {error && <div className="error-message">{error}</div>}
    </form>
  </div>
  );
}
