import React, { Component } from 'react'

import EditVoucher from './EditVoucher'


export default class EditVoucherView extends Component {
  render() {
    return (
      <>
     {/* <Header {...this.props}></Header> */}
     <EditVoucher data={EditVoucher.hero}></EditVoucher>
      </>
    )
  }
}
