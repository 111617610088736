import React, { Component } from 'react'

import RecordRating from './Record_Rating'


export default class DashboardRatingView extends Component {
  render() {
    return (
      <>
     {/* <Header {...this.props}></Header> */}
     <RecordRating data={RecordRating.hero}></RecordRating>
      </>
    )
  }
}
