import React, { Component } from 'react'

import RecordSales from './Record_Sales'


export default class DashboardSalesView extends Component {
  render() {
    return (
      <>
     {/* <Header {...this.props}></Header> */}
     <RecordSales data={RecordSales.hero}></RecordSales>
      </>
    )
  }
}
