import Record from './Record'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'

import React from 'react'

const Dashboard = () => {
  const history = useHistory()
  if(localStorage.getItem("access_token")){
    return (
      <Record data={Dashboard.hero}/>
    )
  }else{
    history.push("/LoginPage")
    history.go(0);
  }
  
}

export default Dashboard