import React, { Component } from 'react'

import EditPrice from './EditPrice'


export default class EditPriceView extends Component {
  render() {
    return (
      <>
     {/* <Header {...this.props}></Header> */}
     <EditPrice data={EditPrice.hero}></EditPrice>
      </>
    )
  }
}
