import React, { useState } from "react";
import { useEffect } from "react";
import imageHero from "../../assets/images/newpoo/test3.png";
import tatacara from "../../assets/images/newpoo/payment1.png";
import startbutton from "../../assets/images/newpoo/startbutton.png";
import poonyalogo from "../../assets/images/poonyalogo.png";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import QRCode from "react-qr-code";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {ReactComponent as YourSvg } from "../../assets/images/logopoonya.svg";


import "../../assets/fonts/MatSaleh.otf";

export default function Hero(props) {
  const history = useHistory();
  const [inputRedeem, setInputRedeem] = useState();
  const [modalShow, setModalShow] = React.useState(false);
  const [modalQris, setModalQris] = React.useState(false);
  const [qrCode, setQRCode] = React.useState();
  const [orderId, setOrderId] = React.useState();
  const [price, setPrice] = React.useState();



  const moveLandingPage = () => {
    console.log(history); // Check if history object is available
    history.push('LandingPage')
    history.go(0)
  };


  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Tata Cara Pembayaran
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
            <img src={tatacara} />
          </center>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function ModalQrisShow(props) {
    return (
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
         <Modal.Header>
        <Modal.Body>
          <center>
            <YourSvg
              
              style={{ width: "80", height: "80", color: "red" }}
            />{" "}
            <div style={{ marginTop: 10 }}></div>
            <h4 className="align-center" style={{ textAlign: "center",  color: "#0950E0",  fontSize: 30,}}>    
              Studio Poonya
            </h4>
          
          </center>
        </Modal.Body>
        </Modal.Header>
        <Modal.Body>
          <h3 className="align-center" style={{ textAlign: "center" }}>
            Scan QR Code
          </h3>
          <center>
            <QRCode
              value={qrCode}
              style={{ width: "180", height: "180", color: "red" }}
            />{" "}
            <div style={{ marginTop: 10 }}></div>
            <h3 className="align-center" style={{ textAlign: "center" }}>    
              Rp {parseFloat(price)}
            </h3>
            <div style={{ marginTop: 10 }}></div>
            <h4
              className="align-center"
              id="hiddentext"
              style={{ textAlign: "center", color: "red", display: "none" }}
            >
              Pastikan kembali pembayaran anda
            </h4>
          </center>
        </Modal.Body>
        <Modal.Footer>
          <center>
          <div
          className="align-center"
          >
            {/* <Button onClick={hiddenbutton}>Close</Button> */}
            <Button onClick={props.onHide} style={{backgroundColor: "red", width:100}}>Close</Button> &nbsp;&nbsp;
            <Button style={{ backgroundColor: "green" }} onClick={() => checkPaymentQR()}>
              Saya Sudah Bayar
            </Button>
          </div>
          </center>
        </Modal.Footer>
      </Modal>
    );
  }

  
  function generateQR(){

    Axios.post("https://be.studiopoonya.net/payment-qr")
    .then((result) => {
      console.log(result)
      setQRCode(result.data.response.qr_string);  
      setOrderId(result.data.response.order_id);
      setPrice(result.data.response.gross_amount);
      setModalQris(true);
    })
    .catch(err => {
      console.log(err)
    })
  }

  function generateQRCustomVoucher(res){
    console.log(res)
    Axios.post(`https://be.studiopoonya.net/payment-qr/custom-voucher/${res}`)
    .then((result) => {
      console.log("sukses")
      console.log(result)
      setQRCode(result.data.response.qr_string);  
      setOrderId(result.data.response.order_id);
      setPrice(result.data.response.gross_amount);
      setModalQris(true);
    })
    .catch(err => {
      console.log(err)
    })
  }

  
  function checkPaymentQR(){
    Axios.get(`https://be.studiopoonya.net/payment-qr/status/${orderId}`)
    .then((res) => {
      console.log(res);
      if(res.data.response.transaction_status == 'settlement'){
        console.log("update status")
        Axios.put(`https://be.studiopoonya.net/payment/update/status/${orderId}`)
        history.push("ClickPage");
      }
      else{
        var x = document.getElementById("hiddentext");
        if (x.style.display === "none") {
          x.style.display = "block";
        } else {
          x.style.display = "none";
        }
      }
    })
    .catch((error) => {
      console.log("error");
    });
  }

  useEffect(() => {
    // Sandbox
    // const snapSrcUrl = 'https://app.sandbox.midtrans.com/snap/snap.js';
    // const myMidtransClientKey = 'SB-Mid-client-LuRh4t6hempU6vad'; //change this according to your client-key

    //Production
    const snapSrcUrl = "https://app.midtrans.com/snap/snap.js";
    const myMidtransClientKey = "Mid-client-XZCR9t5ePdhcGaNC"; //change this according to your client-key

    const script = document.createElement("script");
    script.src = snapSrcUrl;
    script.setAttribute("data-client-key", myMidtransClientKey);
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const RedeemCode = () => {
    Axios.get(
      `https://be.studiopoonya.net/payment/number/${inputRedeem}`
      // `http://localhost:5000/payment/number/${inputRedeem}`
      )
      .then((res) => {
        console.log("135", res.data);

        //Redeem Voucher Code
        if (
          res.data.transaction_number == inputRedeem &&
          res.data.payment_method == "adminredeem" &&
          res.data.pay_status == "Completed"
        ) {
              if(res.data.ticket_used == false){
                console.log(res.data.transaction_number)
                return Axios.put (
                  `https://be.studiopoonya.net/payment/update/status/${res.data.transaction_number}`
                )
                  .then((result) => {
                    Axios.put(`https://be.studiopoonya.net/payment/update/ticketused/${res.data.transaction_number}`)
                    .then((result) => {
                      history.push('ClickPage')
                      history.go(0)
                    })
                    .catch((error) => {
                      console.log("error");
                    });
                 
                  })
                  .catch((error) => {
                    console.log("error");
                  });
              }
              else {
                alert("The code has been used before")
              }
        } 

        
        //Redeem Custom Voucher Code
        if (
          res.data.transaction_number == inputRedeem &&
          res.data.payment_method == "adminredeem" &&
          res.data.pay_status == "Pending"
        ) {
              if(res.data.ticket_used == false){
                console.log(res.data.transaction_number)
                generateQRCustomVoucher(res.data.transaction_number)
                // return Axios.put (
                //   `https://be.studiopoonya.net/payment/update/status/${res.data.transaction_number}`
                // )
                //   .then((result) => {
                //     Axios.put(`https://be.studiopoonya.net/payment/update/ticketused/${res.data.transaction_number}`)
                //     .then((result) => {
                //       history.push('ClickPage')
                //       history.go(0)
                //     })
                //     .catch((error) => {
                //       console.log("error");
                //     });
                 
                //   })
                //   .catch((error) => {
                //     console.log("error");
                //   });
              }
              else {
                alert("The code has been used before")
              }
        } 
       
        //Redeem Referral Code (Payment Completed)
        else if(res.data.payment_method == "referralcode" && res.data.pay_status == "Completed" && res.data.ticket_used == false){
          Axios.put(`https://be.studiopoonya.net/payment/update/ticketused/${res.data.transaction_number}`)
          .then((result) => {
            history.push('ClickPage')
            history.go(0)
          })
          .catch((error) => {
            console.log("error");
          });
        }

        else if(res.data.payment_method == "referralcode" && res.data.pay_status == "Pending" && res.data.ticket_used == false){
          Axios.get(`https://be.studiopoonya.net/payment-qr/status/${res.data.transaction_number}`)
          .then((temp)=> {
            console.log(temp)
            if(temp.data.response.transaction_status == "settlement"){
              console.log(res.data.transaction_number)
              return  Axios.put(`https://be.studiopoonya.net/payment/update/ticketused/${res.data.transaction_number}`)
              .then((result) => {
                console.log("berhasil")
                history.push('ClickPage')
                history.go(0)
              })
              .catch((error) => {
                console.log("error");
              });
            }
            else{
              alert("Make sure your payment is successfull");
            }
          })
        }
       
        
        else if (
          res.data.payment_method == "adminreferral" &&
          res.data.pay_status == "Pending" && res.data.active == true
        ) {
         
          Axios.post(
            `https://be.studiopoonya.net/payment-qr/referral/${res.data.transaction_number}`
            // `http://localhost:5000/payment-qr/referral/${res.data.transaction_number}`
            )
            .then((result) => {
              console.log(result)
              setQRCode(result.data.response.qr_string);  
              setOrderId(result.data.response.order_id);
              setPrice(result.data.response.gross_amount);
              setModalQris(true);
            })
            .catch((error) => {
              console.log("error", error);
            });
        } 
        else if (
          res.data.transaction_number == inputRedeem &&
          res.data.payment_method == "Booking Photo" &&
          res.data.pay_status == "Completed" &&
          res.data.ticket_used == false
        ) {
          Axios.put(
            `https://be.studiopoonya.net/payment/update/ticketused/${res.data.transaction_number}`
          )
            .then((result) => {
              console.log("suksess");
              // console.log(result);
              history.push("ClickPage");
              history.go(0);
            })
            .catch((error) => {
              console.log("error");
            });
        } else {
          alert(
            "Please make sure the payment is completed and the code is correct"
          );
        }
      })
      .catch((error) => {
        console.log("error");
        alert("The code is wrong");
      });
  };

  return (
    <div>
    <div
      style={{
        backgroundImage: `url(${imageHero})`, // Set tatacara image as background
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "1000px", // Set the height of the background div
        textAlign: "center", // Center content horizontally
        display: "flex", // Center content vertically
        justifyContent: "center", // Center content vertically
        alignItems: "center", // Center content vertically
        position: "relative", // Add position relative for button positioning
      }}
    >
      <img
        src={tatacara}
        alt="Tatacara Image"
        height="450px"
        width="1100px"
        style={{
        
          marginTop: -100,
        }}
      />
      
      <img 
      src={startbutton}
        style={{
          position: "absolute",
          paddingTop: 400,
          animation: "zoomInOut 3s infinite alternate", 
          cursor: "pointer"     
        }}
        onClick={moveLandingPage}
      >
        
      </img>
      <style>
      {`
      @keyframes zoomInOut {
        0% {
          transform: scale(1); // Initial scale
        }
        50% {
          transform: scale(1.1); // Zoom in
        }
        100% {
          transform: scale(1); // Zoom out
        }
      }
      `}
    </style>
    </div>
  </div>
  );
}
