import React, { Component } from 'react'

import StartPage from './StartPage'

export default class StartPageView extends Component {
  render() {
    return (
      <>
     {/* <Header {...this.props}></Header> */}
     <StartPage data={StartPage.hero}></StartPage>
      </>
    )
  }
}
