import React, { useState } from "react";
import { useEffect } from "react";

import ratebg from "../../assets/images/newpoo/ratebg.jpg";
import ratesg from "../../assets/images/newpoo/ratesg.jpg";

import Axios from "axios";
import { useHistory } from "react-router-dom";
import { FaStar } from 'react-icons/fa'; // Import star icon

import { ReactComponent as YourSvg } from "../../assets/images/logopoonya.svg";

import "../../assets/fonts/MatSaleh.otf";

export default function RatingPage(props) {
  const history = useHistory();
  const [inputRedeem, setInputRedeem] = useState();
  const [modalShow, setModalShow] = React.useState(false);
  const [modalQris, setModalQris] = React.useState(false);
  const [qrCode, setQRCode] = React.useState();
  const [orderId, setOrderId] = React.useState();
  const [price, setPrice] = React.useState();

  const [hoveredStar, setHoveredStar] = useState(0);
  const [email, setEmail] = useState();
  const [selectedStar, setSelectedStar] = useState(0);
  const [countdown, setCountdown] = useState(60);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(timer);
          window.location.href = 'https://photo.studiopoonya.net/';
        }
        return prevCountdown - 1;
      });
    }, 1000);

    return () => clearInterval(timer); // Clean up the timer on component unmount
  }, []);

  const handleStarHover = (index) => {
    setHoveredStar(index + 1);
  };

  const handleStarClick = (index) => {
    setSelectedStar(index + 1);
    console.log("Selected star value:", index + 1);
  };

  const handleChangeEmail = (val) => {
    setEmail(val.target.value)
  }

  const handleSubmit = () => {
    const body = {
      email: email,
      rating: selectedStar
    }
    Axios.post('https://be.studiopoonya.net/rating', body)
    .then((res) => {
     
      console.log(res)
      console.log("helloo")
      history.push("/FinishPage")
      history.go(0)
    })
    .catch(err => console.log(err)) 
  }

  const handleClosed = () => {
    history.push("/")
    history.go(0)
  }


  return (
     <div
      style={{
        backgroundImage: `url(${ratebg})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "1000px",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        flexDirection: "column",
      }}
    >
      <div style={{ position: "relative", display: "inline-block" }}>
        <img
          src={ratesg}
          style={{
            borderRadius: 15,
            display: "block",
          }}
          alt="Ratesg"
        />
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            color: "#000",
          }}
        >
          <h1 style={{ marginBottom: "20px", whiteSpace: "nowrap" }}>Bagaimana rating untuk sesi foto kali ini?</h1>
          <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
            {[...Array(5)].map((_, index) => (
              <FaStar
                key={index}
                size={80}
                style={{
                  marginRight: "10px",
                  cursor: "pointer",
                  color: (hoveredStar > index || selectedStar > index) ? "#ffc107" : "#aaa"
                }}
                onMouseEnter={() => handleStarHover(index)}
                onMouseLeave={() => setHoveredStar(0)}
                onClick={() => handleStarClick(index)}
              />
            ))}
          </div>
<h1 style={{ marginTop: "65px", whiteSpace: "nowrap" }}> Tulis email kamu untuk mendapatkan promo menarik!</h1>          <input
            type="email"
            placeholder="Email kamu"
            style={{
              marginTop: "20px",
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              width: "80%",
              maxWidth: "400px",
              textAlign: "center" 
            }}
            onChange={(val) => handleChangeEmail(val)}
          /> <br/>
          <button
            style={{
              marginTop: "30px",
              padding: "10px 20px",
              borderRadius: "5px",
              border: "none",
              borderColor:"#FE2081",
              color: "#FE2081",
              cursor: "pointer",
              fontSize: "20px",
              fontWeight: "bold"
            }}
            onClick={() => handleClosed()}
          >
            Close
          </button> &nbsp;
          <button
            style={{
              marginTop: "30px",
              padding: "10px 20px",
              borderRadius: "5px",
              border: "none",
              backgroundColor: "#FE2081",
              color: "#fff",
              cursor: "pointer",
              fontSize: "20px",
              fontWeight: "bold"
            }}
            onClick={() => handleSubmit()}
          >
            Submit
          </button> <br/><br/>
           <h3 style={{ marginTop: "20px", color: "#AEAEAE" }}>
            Akan kembali ke halaman utama dalam {countdown} detik
          </h3>
        </div>
      </div>
    </div>
   
  );
}
