import React, { Component } from 'react'

import ClickPage from './ClickPage'


export default class ClickPageView extends Component {
  render() {
    return (
      <>
     {/* <Header {...this.props}></Header> */}
     <ClickPage data={ClickPage.Click}></ClickPage>
      </>
    )
  }
}
